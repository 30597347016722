<template>
  <div class="journal">
    <Match
      v-if="hasMatch & !stepJournaled & showMatch"
      @clicked="onClickContinueToJournal"
      :step="stepToJournal"
    />

    <div v-if="step.step_template.code.startsWith('MCQ')">
      <DoJournal v-if="stepLoaded & continueJournaling" :step="stepToJournal" />
    </div>
    <div v-else>
      <DoJournal
        v-if="stepLoaded & !stepJournaled & continueJournaling"
        :step="stepToJournal"
      />
    </div>

    <PointJournal
      v-if="stepJournaled"
      :step="journaledStep"
      :hasMatch="hasMatch"
    />

    <NotifyJournal
      v-if="stepJournaled"
      :step="journaledStep"
      :hasMatch="hasMatch"
    />
  </div>
</template>

<script>
import DoJournal from "@/components/DoJournal.vue";
import { StepProvider } from "@/providers";
import NotifyJournal from "@/components/NotifyJournal";
import PointJournal from "@/components/PointJournal";
import Match from "@/components/Match";

export default {
  name: "JournalPage",
  props: {
    stepId: {
      type: [String, Number],
      required: true
    },
    step: {
      type: Object
    }
  },

  components: {
    NotifyJournal,
    DoJournal,
    PointJournal,
    Match
  },

  data() {
    return {
      stepToJournal: {},
      journaledStep: {},
      stepLoaded: false,
      stepJournaled: false,
      hasMatch: false,
      continueJournaling: false,
      showMatch: false
    };
  },

  watch: {
    stepToJournal: function(newStep) {
      if (typeof newStep !== "undefined") {
        if (newStep.status === "completed") {
          this.stepJournaled = true;
          this.journaledStep = newStep;
        }
      }
    }
  },

  created() {
    this.loadStepIfNeeded();

    window.Event.listen("JournalingCompleted", this.journalingCompleted);
  },

  methods: {
    async loadStep() {
      const self = this;

      StepProvider.getStep(this.stepId).then(step => {
        self.stepToJournal = step;
        self.stepLoaded = true;
      });
    },

    loadStepIfNeeded() {
      if (typeof this.step !== "undefined") {
        this.stepToJournal = this.step;
        this.stepLoaded = true;
      }

      this.loadStep();
      this.isStepHasMatch();
    },

    journalingCompleted(step) {
      if (step.id === this.stepId) {
        this.journaledStep = step;
        this.stepJournaled = true;
      }
    },

    async isStepHasMatch() {
      const self = this;
      StepProvider.getMatch(this.stepId).then(response => {
        if (!response || response === null) {
          this.continueJournaling = true;
        } else {
          self.hasMatch = true;
          self.showMatch = true;
        }
      });
    },

    onClickContinueToJournal(value) {
      if (value.id) {
        this.continueJournaling = true;
        this.showMatch = false;
      }
    }
  }
};
</script>

<style scoped></style>
